import { css } from '@linaria/core';
import { memo, VFC } from 'react';
import { theme } from '~/style';
import { breakpoint } from '~/style/breakpoint';
type Props = {
  name: string;
  price: number;
  items: string[];
};

const PlanDetailCard: VFC<Props> = ({
  name,
  price,
  items
}) => {
  return <div className={container}>
      <h2 className={nameText}>{name}</h2>
      <p className={feeText}>{price > 0 ? `月額${price.toLocaleString()}円` : '無料'}</p>

      <ul className={ul}>
        {items.map(item => <li key={item}>{item}</li>)}
      </ul>
    </div>;
};

export default memo(PlanDetailCard);
const container = "cthpfr4";
const nameText = "n3i9r2t";
const feeText = "ftj9s0l";
const ul = "u1m89mnj";

require("./PlanDetailCard.linaria.module.css!=!../../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./PlanDetailCard.tsx");