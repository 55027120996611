import { css } from '@linaria/core';
import { LabelHTMLAttributes, memo, ReactNode, VFC } from 'react';
import { theme } from '~/style';
import { breakpoint } from '~/style/breakpoint';
import { color } from '~/style/color';
type Props = LabelHTMLAttributes<HTMLLabelElement> & {
  children: ReactNode;
  label?: string;
  error?: ReactNode;
};

const FormField: VFC<Props> = ({
  children,
  label,
  error,
  ...props
}) => <div>
    <label className={labelText} {...props}>
      {label}
      {children}
    </label>
    {error && <div className={errorMsg} role="alert">
        {error}
      </div>}
  </div>;

export default memo(FormField);
const labelText = "l1v8nk3d";
export const errorMsg = "ea43bph";

require("./FormField.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./FormField.tsx");