import { css } from '@linaria/core';
import Image from 'next/image';
import React, { ComponentProps, ReactNode, VFC } from 'react';
import { PlanDetailCard } from '~/components/pages/SignUp/Register/PlanDetailCard';
import { theme } from '~/style';
import { breakpoint } from '~/style/breakpoint';
import Background from './Background';
type Props = {
  children: ReactNode;
  title: string;
  planDetail?: ComponentProps<typeof PlanDetailCard>;
};

const TwoSegmentTemplate: VFC<Props> = ({
  children,
  title,
  planDetail
}) => {
  return <Background className={container}>
      <div className={wrapper}>
        <div className={left}>
          <div className={image}>
            <Image src="/logo-white.png" alt="マナベルロゴ画像" layout="fill" objectFit="contain" />
          </div>

          <h1 className={h1}>{title}</h1>
          {planDetail && <PlanDetailCard {...planDetail} />}
        </div>
        <div className={right}>{children}</div>
      </div>
    </Background>;
};

export default TwoSegmentTemplate;
const container = "c1oai6r4";
const wrapper = "wkl30lr";
const image = "i1v5nh21";
const left = "ld2efhk";
const right = "r5nrsso";
const h1 = "h6dui5c";

require("./SignUpLayout.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./SignUpLayout.tsx");