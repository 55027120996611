import axios from 'axios'
import { FormEventHandler, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { api } from '~/api'
import { cacheKeys } from '~/api/cacheKeys'
import { useLazyQuery } from '~/hooks/useLazyQuery'
import { FormError } from '~/style/types'
import { toastServerError } from '~/utils/error'

type Values = {
  email: string
  password: string
}

export const useLoginForm = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<Values>()

  const [dGetUser] = useLazyQuery(cacheKeys.dGetUser, () => api.dashboard.dGetUser(), {
    onSuccess: () => {
      toast.success('ログインしました')
    },
    onError: (error) => {
      toastServerError(error)
    },
  })

  const { mutate, isLoading } = useMutation(api.dashboard.dLoginUser, {
    onSuccess: dGetUser,
    onError(err) {
      if (!axios.isAxiosError(err)) return

      toastServerError(err)

      // Todo サーバが返す[{target: "base", message: "~"}]に合わせて, エラー表示を変更する
      const errors: FormError[] = err.response?.data.errors ?? []
      if (errors.length > 0) {
        setError('email', { message: 'メールアドレスを確認してください' })
        setError('password', { message: 'パスワードを確認してください' })
      }
    },
  })

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (event) => handleSubmit((value: Values) => mutate(value))(event),
    [handleSubmit, mutate]
  )

  return {
    register,
    errors,
    isLoading,
    onSubmit,
  }
}
