import { useCallback, useState } from 'react'
import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from 'react-query'

/**
 * 任意のタイミングで fetch できる `useQuery`
 *
 * @see https://github.com/tannerlinsley/react-query/discussions/1205#discussioncomment-109441
 */
export const useLazyQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) => {
  const [enabled, setEnabled] = useState(false)
  const query = useQuery(queryKey, queryFn, {
    ...options,
    enabled,
    onSuccess: options?.onSuccess,
    onSettled: () => setEnabled(false),
  })

  return [useCallback(() => setEnabled(true), []), query] as const
}
