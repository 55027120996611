import { css } from '@linaria/core';
import Link from 'next/link';
import React, { FormHTMLAttributes, VFC } from 'react';
import { Button } from '~/components/elements/Button/Generic/Button';
import { FormField } from '~/components/elements/FormField';
import { TextInput } from '~/components/elements/Input';
import { theme } from '~/style';
import { breakpoint } from '~/style/breakpoint';
import { color } from '~/style/color';
import { EMAIL } from '~/utils/constants';
import { manaberuLink } from '~/utils/links';
import { useLoginForm } from './useLoginForm';
type Props = FormHTMLAttributes<HTMLFormElement>;

const LoginForm: VFC<Props> = ({ ...props
}) => {
  const {
    register,
    errors,
    isLoading,
    onSubmit
  } = useLoginForm();
  return <form className={form} onSubmit={onSubmit} {...props}>
      <FormField label="メールアドレス" error={errors.email?.message}>
        <TextInput type="email" {...register('email', {
        pattern: {
          value: EMAIL,
          message: '正しいメールアドレスを入力してください。'
        },
        required: 'メールアドレスを入力してください'
      })} invalid={!!errors.email} />
      </FormField>

      <FormField label="パスワード" error={errors.password?.message}>
        <TextInput type="password" {...register('password', {
        required: 'パスワードを入力してください'
      })} invalid={!!errors.password} />
      </FormField>

      <div className={button}>
        <Button type="submit" isLoading={isLoading}>
          ログイン
        </Button>
      </div>

      <Link href={manaberuLink.resetPassword}>
        <a className={toPWReset}>パスワードをお忘れの場合</a>
      </Link>
    </form>;
};

export default LoginForm;
const form = "fitisxc";
const button = "b105pk3l";
const toPWReset = "tgz5ytn";

require("./LoginForm.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./LoginForm.tsx");