import { css } from '@linaria/core';
import Image from 'next/image';
import { memo, VFC } from 'react';
import { theme } from '~/style';
import { breakpoint } from '~/style/breakpoint';
import { SignUpBackground } from '.';
type Props = {
  title: string;
};

const SignUpHeader: VFC<Props> = ({
  title
}) => {
  return <div className={wrapper}>
      <SignUpBackground>
        <div className={container}>
          <div className={image}>
            <Image src="/logo-white.png" alt="マナベルロゴ画像" layout="fill" objectFit="contain" />
          </div>
          <p className={titleStyle}>{title}</p>
        </div>
      </SignUpBackground>
    </div>;
};

export default memo(SignUpHeader);
const wrapper = "weez4f9";
const container = "c3360vb";
const image = "iey96bw";
const titleStyle = "tf2p2uj";

require("./Header.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Header.tsx");