import { css } from '@linaria/core';
import React, { memo, VFC } from 'react';
import { SignUpLayout } from '~/components/layouts/SignUp';
import { LoginForm } from './LoginForm';
import { RegisterButton } from './RegisterButton';

const Login: VFC = () => {
  return <SignUpLayout title="ログイン">
      <LoginForm />
      <RegisterButton className={registration} />
    </SignUpLayout>;
};

export default memo(Login);
const registration = "rqkfbuz";

require("./index.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./index.tsx");