import { NextPage } from 'next'
import { Head } from '~/components/elements/Head'
import Page from '~/components/pages/Login/index'

const Container: NextPage = () => {
  return (
    <>
      <Head title="ログイン" />
      <Page />
    </>
  )
}

export default Container
