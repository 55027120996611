import { css, cx } from '@linaria/core';
import Link from 'next/link';
import React, { AnchorHTMLAttributes, forwardRef } from 'react';
import { BaseButtonAnchor } from '~/components/elements/Button/BaseButton';
import { theme } from '~/style';
import { color } from '~/style/color';
import { manaberuLink } from '~/utils/links';
export const RegisterButton = forwardRef<HTMLAnchorElement, AnchorHTMLAttributes<HTMLAnchorElement>>(function ButtonAnchor({
  className,
  ...props
}, ref) {
  return <Link href={manaberuLink.plan} scroll={false} passHref>
      <BaseButtonAnchor ref={ref} className={cx(base, className)} {...props}>
        新規会員登録はこちら
      </BaseButtonAnchor>
    </Link>;
});
export default RegisterButton;
const base = "b7tl32j";

require("./RegisterButton.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./RegisterButton.tsx");